import { faFacebook, faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const SOCIAL_MEDIAS = [
  {
    id: 'facebook',
    name: 'facebook',
    icon: faFacebook,
    to: 'https://www.facebook.com/lukasz.wojdat.88',
  },
  {
    id: 'github',
    name: 'github',
    icon: faGithub,
    to: 'https://github.com/lukkaszw',
  },
  {
    id: 'linkedin',
    name: 'linkedin',
    icon: faLinkedinIn,
    to: 'https://linkedin.com/in/łukasz-w-65a8201a6',
  }
];

export default SOCIAL_MEDIAS;