import React from 'react';
import PropTypes from 'prop-types';
import styles from './SmallHeader.module.scss';
import clsx from 'clsx';

const SmallHeader = ({ variant, children }) => {
  return ( 
    <h3 className={clsx([styles.root, styles[variant]])}>
      {children}
    </h3>
  );
}

SmallHeader.propTypes = {
  variant: PropTypes.oneOf(['dark', 'light']),
}

SmallHeader.defaultProps = {
  variant: 'light',
}
 
export default SmallHeader;