import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputField.module.scss';
import clsx from 'clsx';

const InputField = ({ id, type, value, label, error, placeholder, onChange }) => {
  return ( 
    <div className={clsx([styles.root, error && styles.error])}>
      <input 
        id={id}
        type={type}
        className={styles.input}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      <label
        className={styles.label}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
}

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

InputField.defaultProps = {
  type: 'text',
};
 
export default InputField;