import React from 'react';
import PropTypes from 'prop-types';
import NavLinkMobile from './NavLinkMobile/NavLinkMobile';
import LangMobileButtons from './LangMobileButtons/LangMobileButtons';
import styles from './NavMobile.module.scss';
import clsx from 'clsx';

import SECTIONS from '../../../settings/sections';

const NavMobile = ({ isActive, onCloseMenu }) => {
  return ( 
    <nav className={clsx([styles.root, isActive && styles.active])}>
      <div className={styles.background}></div>
      <div className={styles.box}>
        <ul className={styles.list}>
          {
            SECTIONS.map((section) => (
              <NavLinkMobile 
                onClick={onCloseMenu}
                key={section.id}
                sectionId={section.id}
              />
            ))
          }
        </ul> 
        <LangMobileButtons />
      </div>
    </nav>
  );
}

NavMobile.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
};
 
export default NavMobile;