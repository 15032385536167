export const translationsEng = {
  welcome: 'Hello, I\'m',
  topBtn: 'Check my portfolio',
  close: 'Close',
  sections: {
    start: 'Start',
    about: 'About me',
    projects: 'Projects',
    skills: 'Skills',
    contact: 'Contact',
  },
  footerSections: {
    sections: 'Sections',
    check: 'Check',
    contact: 'Contact'
  },
  aboutMe: {
    modern: {
      title: 'Modern technologies',
      description: 'I use modern web technologies in my projects.',
    },
    responsive: {
      title: 'Responsive applications',
      description: 'I try to make the applications which look good on all devices.',
    },
    friendly: {
      title: 'Friendly for user',
      description: 'My applications are intuitive and user-friendly.',
    },
    more: {
      title: 'More about me',
      description: 'Hi, my name is Lucas. I\'m a beginner web developer. I have been passionate about creating websites for about 2 years. In my work, I like to use React technology, the Express framework and MongoDB. I try to constantly develop my skills. Currently I am focusing on learning typescript and graphql technologies, and also starting my adventure with react-native. You can see some of my projects below.',
    }
  },
  contact: {
    top: 'Write to me:',
    name: 'Name',
    surname: 'Surname',
    message: 'Your message',
    sendMessage: 'Send message',
    responses: {
      success: {
        top: 'The message has been sent',
        description: 'Thank you for your message :)',
      },
      failure: {
        top: 'Error!',
        description1: 'Please fill in the form fields correctly before submitting! :)',
        description2: 'Unfortunately, the message could not be sent correctly. Please try again in some time. Sorry and best regards.',
      }
    }
  },
  skills: {
    entry: 'Please select one of the skill groups (from the skill wheel) for more detailed information.',
    javascript: ' is my main and favorite programming language. I use it on the client and server side.',
    react: ' is the framework I use to create my api. Most often I use it in combination with react and mongodb (MERN).',
    node: ' is the framework I use to create my api. Most often I use it in combination with react and mongodb (MERN).',
    database: ' is the database system I use. I also know the basics of mysql and graphql.',
  },
  projects: {
    application: 'application',
    code: 'code',
    technologies: 'technologies',
    gallery: 'gallery',
    hideTechnologies: 'Hide technologies',
  },
  card: {
    checkMore: 'Check more',
    checkTechnologies: 'Check technologies',
    rotateBack: 'Rotate card to back side',
    rotateFront: 'Rotate card to front side',
  },
  app1: {
    name: 'Cinema app',
    description: 'Web application (SPA, WPA) of the cinema in English. It has been created using the TDD technique. The application has its own user authorization system, the ability to order tickets for a screening, its own schedule, search engine, and movie descriptions with galleries.',
  },
  app2: {
    name: 'Eyelashes app',
    description: 'Eyelashes app is an application for recording visits to eyelash extensions created for a person who do it. It has its own user account authorization system. The application is still in development.',
  },
  app3: {
    name: 'Game store',
    description: 'Game store is a simple application for an online store with computer games. It was created according to SPA and WPA standards.',
  },
  app4: {
    name: 'Personal Manager',
    description: 'Personal manager my project that helps the user manage his home expenses, set tasks, goals and write his own notes. Additionally, for better organisation the user gets a calendar. The application has its own user account system and is available in two languages: Polish and English. The application was created according to SPA standards.',
  },
  app5: {
    name: 'Working hours',
    description: 'Working Hours is my first android application built with react-native and the expo system. The application allows you to count working hours, holidays and store the history of entries locally on your smartphone.',
  },
  app6: {
    name: 'English flashcards',
    description: 'English flashcards is my second android application built with react-native and expo system. The application uses my own api built with the apollo server library. The mechanisms of the apollo-client package are also included on the client\'s side. The user can learn vocabulary, sentence construction and play two word games. I used my own user authentication and authorization system.',
  },
  sectionsNavigation: {
    goUp: 'To previus section',
    goDown: 'To next section',
    alignToSection: 'Align to the top of the section',
    goToTop: 'Go to top',
  },
  seeProjects: 'See projects',
}