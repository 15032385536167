import { faEnvelope, faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons';

const CONTACT_DATA = [
  {
    id: 'facebook',
    name: 'lukaszwojdat88@gmail.com',
    icon: faEnvelope,
  },
  {
    id: 'phone',
    name: '603 640 252',
    icon: faPhoneSquareAlt,
    to: 'tel:603640252',
  },
];

export default CONTACT_DATA;
