import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import styles from './IconLink.module.scss';
import clsx from 'clsx';

const IconLink = ({ external, asButton, to, icon, name, variant, circle, ...props }) => {

  const compStyles = clsx([styles.root, styles[variant], circle && styles.circle]);

  if(asButton) {
    return (
      <button
        className={compStyles}
        onClick={props.onClick}
      >
       <FontAwesomeIcon className={styles.icon} icon={icon}/> 
      </button>
    )
  }
  
  if(external) {
    return (
      <a 
        className={compStyles}
        href={to} 
        {...props}
        aria-label={name}
      >
        <FontAwesomeIcon className={styles.icon} icon={icon}/>
      </a>
    )
  }

  return ( 
    <Link 
      className={compStyles}
      to={to}
      smooth={true}
      duration={props.duration || 500}
      {...props}
    >
      <FontAwesomeIcon className={styles.icon} icon={icon}/>
    </Link>
  );
}

IconLink.propTypes = {
  external: PropTypes.bool, 
  to: PropTypes.string, 
  icon: PropTypes.object.isRequired, 
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['light', 'dark']),
};

IconLink.defaultProps = {
  variant: 'light',
}
 
export default IconLink;