import React from 'react';
import Section from '../../layout/Section/Section';
import SECTIONS from '../../../settings/sections';
import SmallHeader from '../../common/SmallHeader/SmallHeader';
import InputField from '../../common/InputField/InputField';
import TextareaField from '../../common/TextareaField/TextareaField';
import ContactResponse from './ContactResponse/ContactResponse';
import Button from '../../common/Button/Button';
import { useTranslation } from 'react-i18next';

import styles from './Contact.module.scss';

import useContactForm from '../../../hooks/useContactForm';

const Contact = () => {

  const { t } = useTranslation();

  const {
    emailField,
    nameField,
    textField,
    handleChangeEmail,
    handleChangeName,
    handleChangeText,
    handleSubmit,
    isSending,
    error,
    success,
    handleCloseError,
    handleCloseSuccess,
  } = useContactForm();

  return ( 
    <Section
      variant="dark"
      id={SECTIONS[4].id}
      icon={SECTIONS[4].icon}
    >
      <div data-aos='zoom-in' className={styles.root}>
        <div className={styles.halfPart}>
          <form 
            onSubmit={handleSubmit}
            className={styles.form}
          >
            <SmallHeader variant="dark">
              {t('contact.top')}
            </SmallHeader>
            <InputField 
              value={nameField.value}
              error={nameField.error}
              onChange={handleChangeName}
              id="name"
              placeholder={t('contact.name')}
              label={t('contact.name')}
            />
            <InputField 
              value={emailField.value}
              error={emailField.error}
              onChange={handleChangeEmail}
              id="email"
              type="email"
              placeholder="Email"
              label="Email"
            />
            <TextareaField 
              value={textField.value}
              error={textField.error}
              onChange={handleChangeText}
              id="text"
              placeholder={t('contact.message')}
              label={t('contact.message')}
            />
            <div className={styles.buttonWrapper}>
              <Button
                disabled={isSending}
                type="submit"
              >
                {t('contact.sendMessage')}
              </Button>
            </div>
          </form>
          <ContactResponse
            isSending={isSending} 
            error={error} 
            success={success} 
            onCloseError={handleCloseError} 
            onCloseSuccess={handleCloseSuccess}
          />
        </div>
      </div>
    </Section>
  );
}
 
export default Contact;