import React from 'react';
import PropTypes from 'prop-types';
import NavLink from './NavLink/NavLink';
import LangButtons from '../LangButtons/LangButtons';
import styles from './NavBar.module.scss';
import clsx from 'clsx';
import SECTIONS from '../../../settings/sections';

const NavBar = ({ isActive }) => {
  return ( 
    <nav className={clsx([styles.root, isActive && styles.active])}>
      <ul className={styles.navList}>
        {
          SECTIONS.map(section => (
            <NavLink 
              key={section.id}
              sectionId={section.id}
            />
          ))
        }
      </ul>
      <LangButtons />
    </nav>
  );
}

NavBar.propTypes = {
  isActive: PropTypes.bool.isRequired,
}
 
export default NavBar;