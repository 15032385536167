import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavButton.module.scss';
import clsx from 'clsx';

const NavButton = ({ isActive, onClick }) => {
  return ( 
    <button 
      onClick={onClick}
      className={clsx([styles.root, isActive && styles.active])}
    >
      <div className={styles.bar}></div>
    </button>
  );
}

NavButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
}
 
export default NavButton;