import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';
import styles from './SectionNav.module.scss';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import SECTIONS from '../../../settings/sections';
import useShowNavElement from '../../../hooks/useShowNavElement';


const SectionNav = ({ sections }) => {

  const { t } = useTranslation();

  const {
    isShown
  } = useShowNavElement(200);

  const [currentSectionId, setCurrentSection] = useState(SECTIONS[1].id);

  const { sectionPrevId, sectionNextId, isNextLink,  } = useMemo(() => {

    const currentIndex = sections.findIndex(section => section.id === currentSectionId);
    const isPrevFirst = currentIndex === 0;
    const isNextLink = sections.length > currentIndex + 1;

    return {
      sectionPrevId: isPrevFirst ? sections[0].id : sections[currentIndex - 1].id,
      sectionNextId: isNextLink ? sections[currentIndex + 1].id : '',
      isNextLink,
    }

  }, [currentSectionId, sections]);

  return ( 
    <div className={clsx([styles.root, isShown && styles.shown])}>
      <div>
        <Link 
          className={styles.prevNextLink}
          smooth={true}
          duration={200}
          to={sectionPrevId}
          aria-label={t('sectionsNavigation.goUp')}
        >
          <FontAwesomeIcon className={styles.icon} icon={faChevronUp} />
          <FontAwesomeIcon className={styles.icon} icon={faChevronUp} />
        </Link>
      </div>
      <div className={styles.linkWrapper}>
        {
          sections.map(section => (
            <Link
              key={section.id}
              className={clsx([styles.currentLink, styles[section.id]])}
              to={section.id}
              smooth={true}
              duration={100}
              onSetActive={() => setCurrentSection(section.id)}
              activeClass={styles.active}
              spy={true}
              aria-label={t('sectionsNavigation.alignToSection')}
            >
              <FontAwesomeIcon icon={section.icon} />
            </Link>
          ))
        }
      </div>
      <div>
        <Link 
          to={sectionNextId}
          smooth={true}
          duration={200}
          className={clsx([styles.prevNextLink, !isNextLink && styles.inactive])}
          aria-label={t('sectionsNavigation.goDown')}
        >
          <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
          <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
        </Link>
      </div>
    </div>
  );
}

SectionNav.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
}
 
export default SectionNav;