import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, renderPlace }) => createPortal(children, document.getElementById(renderPlace));

Portal.propTypes = {
  renderPlace: PropTypes.oneOf(['modals']),
};

Portal.defaultProps = {
  renderPlace: 'modals',
}

export default Portal;