import React from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import styles from './ScrollBtn.module.scss';
import clsx from 'clsx';
import useShowNavElement from '../../../hooks/useShowNavElement';

const ScrollBtn = () => {

  const { t } = useTranslation();

  const {
    isShown
  } = useShowNavElement();

  return ( 
    <Link
      to="start"
      smooth={true}
      duration={500}  
      className={clsx([styles.root, isShown && styles.active])}
      aria-label={t('sectionsNavigation.goToTop')}
    >
      <FontAwesomeIcon className={styles.icon} icon={faChevronUp} />
      <FontAwesomeIcon className={styles.icon} icon={faChevronUp} />
    </Link>
  );
}
 
export default ScrollBtn;