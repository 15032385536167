import React from 'react';

import Title from '../Title/Title';
import HeaderBackground2 from '../HeaderBackground/HeaderBackground';

import styles from './Header.module.scss';

const Header = () => {
  return ( 
    <header className={styles.root} id="start">
      <HeaderBackground2 />
      <Title />
    </header>
   );
}
 
export default Header;