import React from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';

import styles from './Title.module.scss';

const Title = () => {

  const { t } = useTranslation();


  return ( 
    <div className={styles.root}>
      <h1 className={styles.title}>
        <span className={styles.greeting}>{t('welcome')}</span>
        <span className={styles.name}>Łukasz Wojdat</span>
      </h1>
      <p className={styles.description}>
        Junior Web Developer
      </p>
      <div>
        <Link 
          className={styles.link} 
          to="about"
          smooth={true}
          duration={500}
        >
          <span className={styles.linkText}>{t('topBtn')}</span>
        </Link>
        <div className={styles.arrows}>
          <FontAwesomeIcon 
            className={styles.icon}
            icon={faChevronDown}
          />
          <FontAwesomeIcon 
            className={styles.icon}
            icon={faChevronDown}
          />
          <FontAwesomeIcon 
            className={styles.icon}
            icon={faChevronDown}
          />
        </div>
      </div>
    </div>
  );
}
 
export default Title;