import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InlineLink from '../InlineLink/InlineLink';

const ListItem = (props) => {

  const Component = props.to ? InlineLink : Paragraph;

  return ( 
    <Component
      {
        ...props
      }
    >
     {props.icon && <FontAwesomeIcon icon={props.icon}/>} {props.children}
    </Component>
  );
}
 
export default ListItem;