import { useState, useCallback } from 'react';
import axios from 'axios';

const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validator = {
  email: (v) => !emailRegexp.test(v),
  name: (v) => !v.length > 0,
  text: (v) => !v.length > 0,
};

const checkAllFields = (fields) => {

  const isError = fields.some((field) => {
    if(field.value.length === 0 || field.error) {
      return true;
    }
    return false;
  });

  return isError;
}

const useContactForm = () => {
  const [emailField, setEmail] = useState({
    value: '',
    error: false,
  });
  const [nameField, setName] = useState({
    value: '',
    error: false,
  });
  const [textField, setText] = useState({
    value: '',
    error: false,
  });

  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChangeEmail = useCallback((e) => setEmail({
    value: e.target.value,
    error: validator.email(e.target.value),
  }), [setEmail]);
  const handleChangeName = useCallback((e) => setName({
    value: e.target.value,
    error: validator.name(e.target.value),
  }), [setName]);
  const handleChangeText = useCallback((e) => setText({
    value: e.target.value,
    error: validator.text(e.target.value),
  }), [setText]);

  const handleCloseError = useCallback(() => setError(''),[setError]);
  const handleCloseSuccess = useCallback(() => setSuccess(false), [setSuccess]);
  const handleResetForm = useCallback(() => {
    setEmail({ value: '', error: false });
    setName({ value: '', error: false });
    setText({ value: '', error: false });
  }, [setEmail, setName, setText]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    if(checkAllFields([emailField, nameField, textField])) {
      setError('contact.responses.failure.description1');
      return;
    }

    setIsSending(true);

    const data = {
      email: emailField.value,
      name: nameField.value,
      message: textField.value,
    };
    
    axios.post('https://lukkiasz-cinema.herokuapp.com/api/messages', {
      external: true,
      ...data,
    })
      .then(() => {
        setIsSending(false);
        handleResetForm();
        setSuccess(true);
      })
      .catch(() => {
        setIsSending(false);
        setError('contact.responses.failure.description2');
      });
    
  }, [emailField, nameField, textField, handleResetForm]);

  return {
    emailField,
    nameField,
    textField,
    isSending,
    error,
    success,
    handleCloseError,
    handleCloseSuccess,
    handleChangeEmail,
    handleChangeName,
    handleChangeText,
    handleSubmit,
  };
}

export default useContactForm;