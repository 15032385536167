import React from 'react';
import PropTypes from 'prop-types';
import InlineLink from '../../../common/InlineLink/InlineLink';
import { useTranslation } from 'react-i18next';

import styles from './FooterList.module.scss';
import clsx from 'clsx';

const FooterList = ({ items, component, columnOnSmall, additionaProps }) => {

  const { t } = useTranslation();

  const Component = component || InlineLink;

  return ( 
      <ul className={clsx([styles.root, columnOnSmall && styles.columnOnSmall])}>
        {
          items.map(item => (
            <li 
              className={styles.item}
              key={item.id}
            >
              {
                component ?
                  <Component
                    to={item.to}
                    target="__blank"
                    external
                    name={item.name}
                    icon={item.icon}
                    {...additionaProps}
                  >
                    {item.name || t(item.transKey)}
                  </Component>
                  :
                  <Component 
                    to={item.id}
                    {...additionaProps}
                  >
                    {item.name || t(item.transKey)}
                  </Component>
              }
            </li>
          ))
        }
      </ul>   
  );
}

FooterList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  additionaProps: PropTypes.object,
  columnOnSmall: PropTypes.bool,
}
 
export default FooterList;