import { useState, useEffect } from 'react';

const useShowNavElement = (basePosition = 200) => {

  const [isShown, setIsShow] = useState(false);
  
  useEffect(() => {
    const checkPosition = () => {
      const scrollPos = window.scrollY;
      if(scrollPos > basePosition && !isShown) {
        setIsShow(true);
      } else if (scrollPos < basePosition && isShown) {
        setIsShow(false);
      }
    }
    checkPosition();
    window.addEventListener('scroll', checkPosition);
    return () => window.removeEventListener('scroll', checkPosition);
  }, [setIsShow, isShown, basePosition]);

  return {
    isShown,
  }
}

export default useShowNavElement;
  
