import { faDatabase, faPalette } from '@fortawesome/free-solid-svg-icons';
import { faCss3Alt, faGithub, faHtml5, faNode, faNodeJs, faNpm, faReact, faSass, faYarn } from '@fortawesome/free-brands-svg-icons';

const SKILLS = [
  {
    name: 'all',
    icon: null,
    part: 0,
    description: {
      info: 'skills.entry',
    },
    technologies: [
      {
        icon: faNodeJs,
        color: '#fdd835',
        name: 'Javasript',
      },
      {
        icon: faCss3Alt,
        color: '#00d9cc',
        name: 'CSS',
      },
      {
        icon: faSass,
        color: '#bf4080',
        name: 'Scss',
      },
      {
        icon: faHtml5,
        color: '#F16529',
        name: 'HTML5',
      },
      {
        icon: faReact,
        color: '#00a2ff',
        name: 'React, React Native',
      },
      {
        icon: faNode,
        color: '#1e7003',
        name: 'Node.js, Express.js',
      },
      {
        icon: faDatabase,
        color: '#008000',
        name: 'MongoDB, MySQL, GraphQL',
      },
      {
        icon: faGithub,
        color: '#000',
        name: 'git, github',
      },
      {
        icon: faNpm,
        color: '#cc3534',
        name: 'npm, yarn',
      },
      {
        icon: faPalette,
        color: 'purple',
        name: 'Bootstrap, Material-ui',
      },
      {
        svg: '/svgs/typescript.svg',
        name: 'typescript',
      },
    ]
  },
  {
    name: 'js',
    icon: faNodeJs,
    part: 1,
    description: {
      name: 'Javascript',
      info: 'skills.javascript',
    },
    technologies: [
      {
        icon: faNodeJs,
        color: '#fdd835',
        name: 'Javasript',
      },
      {
        icon: faNode,
        color: '#1e7003',
        name: 'Node.js',
      },
      {
        icon: faNpm,
        color: '#cc3534',
        name: 'npm',
      },
      {
        icon: faYarn,
        color: '#1476a2',
        name: 'yarn',
      },
      {
        svg: '/svgs/expressjs.svg',
        name: 'Express.js',
      },
      {
        svg: '/svgs/ajax.svg',
        name: 'ajax',
      },
      {
        icon: faReact,
        color: '#00a2ff',
        name: 'React',
      },
      {
        icon: faReact,
        color: '#00a2ff',
        name: 'React Native',
      },
      {
        svg: '/svgs/typescript.svg',
        name: 'typescript',
      },
    ]
  },
  {
    name: 'react',
    icon: faReact,
    part: 4,
    description: {
      name: 'React',
      info: 'skills.react',
    },
    technologies: [
      {
        icon: faReact,
        color: '#00a2ff',
        name: 'React',
        nested: [
          'react-hooks',
          'react + redux',
          'react-query',
          'react final form',
          'react + styled-components',
          'react + material-ui',
          'react + mongoDB + express',
          'react + typescript',
          'react + graphql',
        ]
      },
      {
        icon: faReact,
        color: '#00a2ff',
        name: 'React Native',
      },
    ]
  },
  {
    name: 'node',
    icon: faNode,
    part: 2,
    description: {
      name: 'Express.js',
      info: 'skills.node',
    },
    technologies: [
      {
        icon: faNode,
        color: '#1e7003',
        name: 'Node.js',
      },
      {
        svg: '/svgs/expressjs.svg',
        name: 'Express.js',
        nested: [
          'express + mongoDB',
          'mongoose.js',
        ],
      },
      {
        svg: '/svgs/graphql.svg',
        name: 'Apollo Server',
      },
    ],
  },
  {
    name: 'database',
    icon: faDatabase,
    part: 3,
    description: {
      name: 'MongoDB',
      info: 'skills.database',
    },
    technologies: [
      {
        svg: '/svgs/mongodb.svg',
        name: 'MongoDB',
      },
      {
        svg: '/svgs/mysql.svg',
        name: 'MySQL',
      },
      {
        svg: '/svgs/graphql.svg',
        name: 'GraphQL',
      },
    ],
  }
];

export default SKILLS;