import React, { useCallback, useState } from 'react';
import Section from '../../layout/Section/Section';
import Row from '../../layout/Row/Row';
import Card from '../../common/Card/Card';
import Loader from '../../common/Loader/Loader';
import SECTIONS from '../../../settings/sections';
import styles from './Projects.module.scss';
import PROJECTS from '../../../settings/projects';

const ProjectModal = React.lazy(() => import('./ProjectModal/ProjectModal'));

const Projects = () => {

  const [viewedProject, setViewedProject] = useState(null);

  const handleCloseProject = useCallback(() => setViewedProject(null), [setViewedProject]);

  const selectedProject = (viewedProject >= 0 && viewedProject <= PROJECTS.length - 1) && PROJECTS[viewedProject];

  return ( 
    <Section
      variant='dark'
      id={SECTIONS[2].id}
      icon={SECTIONS[2].icon}
    >
      <div data-aos="fade-up" className={styles.root}>
        <Row>
          {
            PROJECTS.map((project, index) => (
              <Card 
                key={project.title}
                {...project}
                index={index}
                onSelectProject = {setViewedProject}
              />
            ))
          }
        </Row>
      </div>
      {
        !!selectedProject &&
          <React.Suspense fallback={<Loader />}>
            <ProjectModal 
              project={selectedProject}
              onClose={handleCloseProject}
            />  
          </React.Suspense>

      }

    </Section>
  );
}
 
export default Projects;