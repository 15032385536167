import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faNodeJs, faReact } from '@fortawesome/free-brands-svg-icons';

const PROJECTS = [
  {
    imageUrl: '/images/projects/cinema-main.png',
    title: 'app1.name',
    category: 'fullstack app',
    smallInfo: 'express + react + mongoDB',
    linkToApp: 'https://lukkiasz-cinema.herokuapp.com/',
    linkToGithub: 'https://github.com/lukkaszw/cinema',
    description: 'app1.description',
    languages: ['eng'],
    gallery: ['cinema-1.jpg', 'cinema-2.jpg', 'cinema-3.jpg', 'cinema-4.jpg', 'cinema-5.jpg', 'cinema-6.jpg'],
    technologies: [
      {
        icon: faReact,
        color: '#61DBFB',
        name: 'React',
        nested: [
          'react-hooks',
          'react-router',
          'redux',
          'axios',
          'scss',
          'prop-types',
        ],
      },
      {
        icon: faNodeJs,
        color: '#68A063',
        name: 'node.js',
        nested: [
          'express.js',
          'socket.io',
          'jsonwebtoken',
          'mongoose',
        ],
      },
      {
        icon: faDatabase,
        color: '#3FA037',
        name: 'mongoDB',
        nested: [
          'mongoDB Atlas',
        ],
      }
    ]
  },
  {
    imageUrl: '/images/projects/eyelashes-main.png',
    title: 'app2.name',
    category: 'fullstack app',
    smallInfo: 'express + react + mongoDB',
    linkToApp: 'https://eyelashes-note.herokuapp.com',
    linkToGithub: 'https://github.com/lukkaszw/eyeleshes',
    description: 'app2.description',
    languages: ['pl'],
    gallery: ['eyelashes-1.jpg', 'eyelashes-2.jpg', 'eyelashes-3.jpg', 'eyelashes-4.jpg', 'eyelashes-5.jpg', 'eyelashes-6.jpg'],
    technologies: [
      {
        icon: faReact,
        color: '#61DBFB',
        name: 'React',
        nested: [
          'react-hooks',
          'react-router',
          'redux',
          'react-query',
          'axios',
          'scss',
          'prop-types',
        ],
      },
      {
        icon: faNodeJs,
        color: '#68A063',
        name: 'node.js',
        nested: [
          'express.js',
          'jsonwebtoken',
          'mongoose',
        ],
      },
      {
        icon: faDatabase,
        color: '#3FA037',
        name: 'mongoDB',
        nested: [
          'mongoDB Atlas',
        ],
      }
    ]
  },
  {
    imageUrl: '/images/projects/game-store-main.png',
    title: 'app3.name',
    category: 'fullstack app',
    smallInfo: 'express + react + mongoDB',
    linkToApp: 'https://lukkiasz-game-store.herokuapp.com/',
    linkToGithub: 'https://github.com/lukkaszw/shop-last-project',
    description: 'app3.description',
    languages: ['eng'],
    gallery: ['game-store-1.jpg', 'game-store-2.jpg', 'game-store-3.jpg', 'game-store-4.jpg'],
    technologies: [
      {
        icon: faReact,
        color: '#61DBFB',
        name: 'React',
        nested: [
          'react-router',
          'redux',
          'axios',
          'scss',
          'prop-types',
        ],
      },
      {
        icon: faNodeJs,
        color: '#68A063',
        name: 'node.js',
        nested: [
          'express.js',
          'mongoose',
        ],
      },
      {
        icon: faDatabase,
        color: '#3FA037',
        name: 'mongoDB',
        nested: [
          'mongoDB Atlas',
        ],
      }
    ]
  },
  {
    imageUrl: '/images/projects/pm-main.png',
    title: 'app4.name',
    category: 'fullstack app',
    smallInfo: 'express + react + mongoDB',
    linkToApp: 'https://lukkiasz-pm.herokuapp.com/',
    linkToGithub: 'https://github.com/lukkaszw/personal-manager',
    description: 'app4.description',
    languages: ['pl', 'eng'],
    gallery: ['pm-1.jpg', 'pm-2.jpg', 'pm-3.jpg', 'pm-4.jpg', 'pm-5.jpg'],
    technologies: [
      {
        icon: faReact,
        color: '#61DBFB',
        name: 'React',
        nested: [
          'react-hooks',
          'react-router',
          'redux',
          'react-query',
          'react-i18next',
          'axios',
          'react-final-form',
          'material-ui',
          'styled-components',
          'prop-types',
        ],
      },
      {
        icon: faNodeJs,
        color: '#68A063',
        name: 'node.js',
        nested: [
          'express.js',
          'moment.js',
          'mongoose',
          'jsonwebtoken',
        ],
      },
      {
        icon: faDatabase,
        color: '#3FA037',
        name: 'mongoDB',
        nested: [
          'mongoDB Atlas',
        ],
      }
    ]
  },
  {
    imageUrl: '/images/projects/working-hours-main.png',
    title: 'app5.name',
    category: 'android app',
    smallInfo: 'react-native + sql-lite',
    linkToApp: 'https://expo.io/@lukkaszw/projects/godziny-pracy',
    linkToGithub: 'https://github.com/lukkaszw/work-hour-app',
    description: 'app5.description',
    languages: ['pl'],
    gallery: ['working-hours-1.jpg', 'working-hours-2.jpg'],
    technologies: [
      {
        icon: faReact,
        color: '#61DBFB',
        name: 'React Native',
        nested: [
          'react-hooks',
          'redux',
          'react-navigation',
          'react-native-calendars',
        ],
      },
      {
        icon: faDatabase,
        color: '#3FA037',
        name: 'database',
        nested: [
          'SQLite',
        ],
      }
    ]
  },
  {
    imageUrl: '/images/projects/fiszki-main.png',
    title: 'app6.name',
    category: 'android app + api',
    smallInfo: 'apollo + react-native + mongoDB',
    linkToApp: 'https://expo.io/@lukkaszw/fiszki-ang',
    linkToGithub: 'https://github.com/lukkaszw/englishquiz-mobileapp',
    description: 'app6.description',
    languages: ['pl'],
    gallery: ['fiszki-1.jpg', 'fiszki-2.jpg'],
    technologies: [
      {
        icon: faReact,
        color: '#61DBFB',
        name: 'React Native',
        nested: [
          'react-hooks',
          'apollo-client',
          'redux',
          'react-navigation',
          'react-native-paper',
          'react-native-reanimated',
          'typescript',
        ],
      },
      {
        icon: faNodeJs,
        color: '#68A063',
        name: 'node.js',
        nested: [
          'apollo-server',
          'typescript',
          'mongoose',
          'jsonwebtoken',
        ],
      },
      {
        icon: faDatabase,
        color: '#3FA037',
        name: 'mongoDB',
        nested: [
          'mongoDB Atlas',
        ],
      }
    ]
  }
];

export default PROJECTS;