import React from 'react';
import styles from './Footer.module.scss';
import clsx from 'clsx';
import SmallHeader from '../../common/SmallHeader/SmallHeader';
import FooterList from './FooterList/FooterList';
import ListItem from '../../common/ListItem/ListItem';
import IconLink from '../../common/IconLink/IconLink';
import { useTranslation } from 'react-i18next';

import SECTIONS from '../../../settings/sections';
import SOCIAL_MEDIAS from '../../../settings/socialMedias';
import CONTACT_DATA from '../../../settings/contactData';

const Footer = () => {

  const { t } = useTranslation();

  return ( 
    <footer className={styles.root}>
      <div className={styles.container}>
        <div className={clsx([styles.column, styles.sectionsColumn])}>
          <SmallHeader>
            {t('footerSections.sections')}
          </SmallHeader>
          <nav>
            <FooterList 
              items={SECTIONS}
            />
          </nav>
        </div>
        <div className={clsx([styles.column, styles.checkColumn])}>
          <SmallHeader>
          {t('footerSections.check')}
          </SmallHeader>
          <FooterList 
            items={SOCIAL_MEDIAS}
            component={IconLink}
            additionaProps={{ external: true, circle: true }}
          />
        </div>
        <div className={clsx([styles.column, styles.contactColumn])}>
          <SmallHeader>
          {t('footerSections.contact')}
          </SmallHeader>
          <FooterList 
            items={CONTACT_DATA}
            component={ListItem}
            columnOnSmall
          />
        </div>
      </div>
      <p>
        &copy; Łukasz Wojdat 2020
      </p>
    </footer>
  );
}
 
export default Footer;