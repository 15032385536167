import React from 'react';
import PropTypes from 'prop-types';
import styles from './Paragraph.module.scss';
import clsx from 'clsx';

const Paragraph = ({ children, variant }) => {
  return ( 
    <p className={clsx([styles.root, styles[variant]])}>
      {children}
    </p>
  );
}

Paragraph.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark'])
};

Paragraph.default = {
  variant: 'light',
}

 
export default Paragraph;