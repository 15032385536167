export const translationsPl = {
  welcome: 'Witaj, jestem',
  topBtn: 'Zobacz porfolio',
  close: 'Zamknij',
  sections: {
    start: 'Start',
    about: 'O mnie',
    projects: 'Projekty',
    skills: 'Umiejętności',
    contact: 'Kontakt',
  },
  footerSections: {
    sections: 'Sekcje',
    check: 'Sprawdź',
    contact: 'Kontakt'
  },
  aboutMe: {
    modern: {
      title: 'Nowoczesne technologie',
      description: 'W swoich projektach wykorzystuję nowoczesne technologie webowe.',
    },
    responsive: {
      title: 'Responsywne aplikacje',
      description: 'Staram się by aplikacje wyglądały dobrze na wszystkich urządzeniach.',
    },
    friendly: {
      title: 'Przyjazne dla użytkownika',
      description: 'Moje aplikacje są intuicyjne w użytkowaniu oraz przyjazne dla użytkownika.',
    },
    more: {
      title: 'Więcej o mnie',
      description: 'Cześć, nazywam się Łukasz Wojdat. Jestem początkującym web developerem. Pasjonuję się tworzeniem stron internetowych od około 2 lat. W swoich pracach najbardziej lubię korzystać z technologii React, framework\'a Express oraz MongoDB. Staram się stale rozwijać swoje umiejętności. Obecnie skupiam się na poznaniu technologii typescript oraz graphql, a także zaczynam swoją przygodę z react-native. Poniżej można zobaczyć kilka z moich projektów.',
    }
  },
  contact: {
    top: 'Napisz do mnie:',
    name: 'Imię',
    message: 'Podaj wiadomość',
    sendMessage: 'Wyślij wiadomość',
    responses: {
      success: {
        top: 'Wiadomość wysłana',
        description: 'Dziękuję za wysłanie wiadomości :)',
      },
      failure: {
        top: 'Błąd!',
        description1: 'Proszę poprawnie uzupełnić pola formularza przed wysłaniem! :)',
        description2: 'Niestety nie udało się poprawnie wysłać wiadmości. Proszę o ponowną próbę za jakiś czas. Przepraszam i pozdrawiam.',
      }
    }
  },
  skills: {
    entry: 'Proszę wybrać jedną z grup umiejętności (z koła umiejętności) by zobaczyć więcej szczegółowych informacji.',
    javascript: ' to mój głowny i ulubiony język programowania. Używam go zarówno po stronie klienta jak i serwera.',
    react: ' to biblioteka, której używam do tworzenia interfejsów użytkownika. Obecnie uczę się również tworzyć aplikacje mobilne przy użyciu React Native.',
    node: ' to framework, którego używam do tworzenia swoich api. Najczęściej stosuje w połączeniu z react oraz mongodb (MERN).',
    database: ' to używany przeze mnie system bazodanowy. Znam również podstawy mysql i graphql.',
  },
  projects: {
    application: 'aplikacja',
    code: 'kod',
    technologies: 'technologie',
    gallery: 'galeria',
    hideTechnologies: 'Ukryj technologie',
  },
  card: {
    checkMore: 'Zobacz więcej',
    checkTechnologies: 'Sprawdź technologie',
    rotateBack: 'Przekręć do tylnej strony',
    rotateFront: 'Przekręć do przedniej strony',
  },
  app1: {
    name: 'Cinema app',
    description: 'Aplikacja webowa (SPA, WPA) kina w języku angielskim. Tworzona techniką TDD. Aplikacja posiada własny system autoryzacji użytkowników, możliwość zamawiania biletów na seans, własny grafik, wyszukiwarkę i opisy filmów wraz z galeriami.',
  },
  app2: {
    name: 'Eyelashes app',
    description: 'Aplikacja do rejestrowania wizyt przy przedłużaniu rzęs tworzona na potrzeby jednej z osób, która się tym trudni. Posiada własny system autoryzacji kont użytkowników. Aplikacja jest wciąż w fazie rozwoju.',
  },
  app3: {
    name: 'Game store',
    description: 'Prosta aplikacja sklepu internetowego z grami komputerowymi. Była tworzona w oparciu o standardy SPA, WPA.',
  },
  app4: {
    name: 'Personal Manager',
    description: 'Personal manager to mój autorski projekt pomagający użytkownikowi zarządzać jego domowymi wydatkami, stawiać sobie zadania i cele, zapisywać własne notatki. Dodatkowo dla lepszej organizacji użytkownik dostaje kalendarz. Aplikacja posiada własny system kont użytkowników i jest w dwóch językach: polskim i angielskim. Aplikacja jest tworzona w oparciu o standard SPA.',
  },
  app5: {
    name: 'Godziny pracy',
    description: 'Godziny pracy to moja pierwsza aplikacja na androida zbudowana za pomocą react-native oraz systemu expo. Aplikacja pozwala na zliczanie godzin pracy, urlopów oraz przechowywanie historii wpisów lokalnie na smartfonie.',
  },
  app6: {
    name: 'Fiszki do j. angielskiego',
    description: 'Fiszki do j. angielskiego to moja druga aplikacja na androida zbudowana za pomocą react-native oraz systemu expo. Aplikacja korzysta z mojego autorskiego api zbudowanego przy pomocy biblioteki apollo server. Po stronie klienta zawarto również mechanizmy paczki apollo-client. Użytkownik może uczyć się słówek, konstrukcji zdań i zagrać w dwie słowne gry. Zastosowałem własny system uwierzytelniania i autoryzacji użytkowników.',
  },
  sectionsNavigation: {
    goUp: 'Do poprzedniej sekcji',
    goDown: 'Do następnej sekcji',
    alignToSection: 'Wyrównaj do góry sekcji',
    goToTop: 'Idź do góry strony',
  },
  seeProjects: 'Zobacz projekty',
}