import React from 'react';
import clsx from 'clsx';
import styles from './LangButtons.module.scss';
import LANGUAGES from '../../../settings/languages';
import { useTranslation } from 'react-i18next';


const LangButtons = () => {
  const { i18n } = useTranslation();

  return ( 
    <div className={styles.root}>
      <div className={styles.box}>
        {
          LANGUAGES.map(language => (
            <button 
              onClick={() => {
                i18n.changeLanguage(language.value);
              }}
              className={clsx([styles.button, language.value === i18n.language && styles.active])}
              key={language.name}
            >
              {language.name}
            </button>
          ))
        }
      </div>
    </div>
  );
}
 
export default LangButtons;