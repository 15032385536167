import React from 'react';
import PropTypes from 'prop-types';
import styles from './Backdrop.module.scss';

const Backdrop = ({ onClick }) => <div onClick={onClick} className={styles.root}></div>;

Backdrop.propTypes = {
  onClick: PropTypes.func,
};
 
export default Backdrop;