import React, { useState, useCallback } from 'react';
import NavBar from '../NavBar/NavBar';
import ScrollBtn from '../ScrollBtn/ScrollBtn';
import SectionNav from '../SectionNav/SectionNav';
import NavMobile from '../NavMobile/NavMobile';
import NavButton from '../NavButton/NavButton';
import SECTIONS from '../../../settings/sections';

const Navigation = () => {

  const [isActive, setActive] = useState(false);
  const handleToggleNav = useCallback(() => setActive(prevValue => !prevValue), [setActive]);
  const onDelayClose = useCallback(() => {
    setTimeout(() => {
      setActive(false);
    }, [1000]);
  }, [setActive]);

  return ( 
    <div>
      <NavBar 
        isActive={isActive}
      />
      <SectionNav 
        sections={SECTIONS}
      />
      <ScrollBtn />
      <NavMobile 
        onCloseMenu={onDelayClose}
        isActive={isActive}
      />
      <NavButton 
        onClick={handleToggleNav}
        isActive={isActive}
      />
    </div>
  );
}
 
export default Navigation;