import React from 'react';
import PropTypes from 'prop-types';
import SmallHeader from '../../../common/SmallHeader/SmallHeader';
import Paragraph from '../../../common/Paragraph/Paragraph';
import { useTranslation  } from 'react-i18next';

import styles from './ShortInfo.module.scss';

const ShortInfo = ({ imageUrl,  animation, transKey }) => {

  const { t } = useTranslation();

  return ( 
    <div data-aos={animation} className={styles.root}>
      <div>
        <img className={styles.image} src={imageUrl} alt={t(`${transKey}.title`)} />
      </div>
      <div className={styles.info}>
        <SmallHeader variant="dark">
          {t(`${transKey}.title`)}
        </SmallHeader>
        <div className={styles.description}>
          <Paragraph variant="dark">
            {t(`${transKey}.description`)}
          </Paragraph>
        </div>
      </div>
    </div>
  );
}

ShortInfo.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  transKey: PropTypes.string.isRequired,
  animation: PropTypes.oneOf(['fade-right', 'fade-left', 'fade-up', ''])
};
 
export default ShortInfo;