import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../SectionTitle/SectionTitle';
import { useTranslation  } from 'react-i18next';

import styles from './Section.module.scss';
import clsx from 'clsx';

const Section = ({ id, icon, variant, children }) => {
  const { t } = useTranslation();

  return ( 
    <section 
      id={id}
      className={clsx([styles.root, styles[variant]])}
    >
      <SectionTitle 
        icon={icon}
        variant={variant}
      >
        {t(`sections.${id}`)}
      </SectionTitle>
      <div>
        {children}
      </div>
    </section>  
  );
}

Section.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['dark', 'light']),
};

Section.defaultProps = {
  variant: 'dark',
};
 
export default Section;