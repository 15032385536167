import React, { useState, useMemo } from 'react';
import Section from '../../layout/Section/Section';
import SECTIONS from '../../../settings/sections';
import styles from './Skills.module.scss';
import SkillsWheel from './SkillsWheel/SkillsWheel';
import SkillsDescription from './SkillsDescription/SkillsDescription';

import SKILLS from '../../../settings/skills';

const Skills = () => {
  const [skillGroup, setSkillGroup] = useState(0);

  const skillsInWheel = useMemo(() => {
    return SKILLS.filter(skillGroup => skillGroup.part > 0);
  }, []);

  const currentSkillGroup = useMemo(() => {
    return SKILLS.find(skills => skills.part === skillGroup);
  }, [skillGroup]);

  return ( 
    <Section 
      variant="light"
      id={SECTIONS[3].id}
      icon={SECTIONS[3].icon}
    > 
      <div data-aos="zoom-in" className={styles.root}>
        <SkillsWheel 
          skillsGroups={skillsInWheel}
          currentSkills={skillGroup}
          onChoseSkills={setSkillGroup}
        />
        <SkillsDescription 
          skillsGroup={currentSkillGroup}
        />
      </div>
    </Section>
  );
}
 
export default Skills;