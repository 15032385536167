import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import styles from './InlineLink.module.scss';
import clsx from 'clsx';

const InlineLink = ({ external, to, variant, children, ...props }) => {

  if(external) {
    return (
      <a 
        className={clsx([styles.root, styles[variant]])} 
        href={to} 
        {...props}
      >
        {children}
      </a>
    )
  }

  return ( 
    <Link 
      to={to}
      smooth={true}
      duration={props.duration || 500}
      className={clsx([styles.root, styles[variant]])}
      {...props}
    >
      {children}
    </Link>
  );
}

InlineLink.propTypes = {
  external: PropTypes.bool,
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['dark', 'light']),
};
 
InlineLink.defaultProps = {
  external: false,
  variant: 'light',
};

export default InlineLink;