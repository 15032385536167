import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './SkillsWheel.module.scss';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

const SkillsWheel = ({ skillsGroups, currentSkills, onChoseSkills }) => {

  const degree = useMemo(() => {
    if(currentSkills === 0) {
      return 0;
    }
    return (currentSkills - 1) * 90 + 45;
  }, [currentSkills]);

  return (
    <div className={styles.root}>
      <div 
        style={{
          transform: `rotate(${(degree)}deg)`,
        }}
        className={styles.wheel}
      >
        {
          skillsGroups.map(skillsGroup => (
            <div
              key={skillsGroup.name}
              className={clsx([styles.part, styles[skillsGroup.name], currentSkills === skillsGroup.part && styles.active])}
            >
              <div className={styles.circle} 
                onClick={() => onChoseSkills(skillsGroup.part)}
              />
              <FontAwesomeIcon 
                onClick={() => onChoseSkills(skillsGroup.part)} 
                className={styles.icon} 
                icon={skillsGroup.icon}
              />
            </div>
          ))
        }
        <div className={styles.crossX} />
        <div className={styles.crossY} />
        <div 
          className={styles.circleInside} 
        >
          {
            !!currentSkills &&
              <FontAwesomeIcon 
                style={{
                  transform: `translate(-50%, -50%) rotate(-${degree}deg)`,
                }}
                onClick={() => onChoseSkills(0)}
                className={clsx([styles.icon, styles.powerOff])} icon={faPowerOff}
              />
          }
        </div>
      </div>
    </div>
  );
}
 
SkillsWheel.propTypes = {
  skillsGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentSkills: PropTypes.number.isRequired,
  onChoseSkills: PropTypes.func.isRequired,
}

export default SkillsWheel;