import React from 'react';

import Particles from 'react-particles-js';

import particlesConfig from '../../../settings/particles';

const HeaderBackground = () => {
  return ( 
    <div style={{ position: 'absolute' }}>
      <Particles 
        height="100vh"
        width="100vw"
        params={particlesConfig}
      />
    </div>
  );
}
 


export default HeaderBackground;