import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './SectionTitle.module.scss';
import clsx from 'clsx';

const SectionTitle = ({ children, variant, icon }) => {
  return (
    <header className={clsx([styles.root, styles[variant]])}>
      <h2 className={styles.title}>
        <FontAwesomeIcon className={styles.icon} icon={icon}/>
        <span className={styles.text}>{children}</span>
        <span className={styles.textPlaceholder}>
          {children}
        </span>
      </h2>
    </header>
  );
}

SectionTitle.propTypes = {
  icon: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['light', 'dark']),
};

SectionTitle.defaultProps = {
  variant: 'light',
};
 
export default SectionTitle;