import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../common/Alert/Alert';
import Loader from '../../../common/Loader/Loader';
import { useTranslation } from 'react-i18next';

const ContactResponse = ({ isSending, error, success, onCloseError, onCloseSuccess }) => {

  const { t } = useTranslation();

  return ( 
    <>
      {
        error &&
          <Alert 
            title={t('contact.responses.failure.top')}
            message={t(error)}
            onClick={onCloseError}
          />
      }
      {
        success &&
          <Alert 
            title={t('contact.responses.success.top')}
            positive
            message={t('contact.responses.success.description')}
            onClick={onCloseSuccess}
          />
      }
      {
        isSending && <Loader />
      }
    </>
  );
}

ContactResponse.propTypes = {
  isSending: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  onCloseError: PropTypes.func.isRequired,
  onCloseSuccess: PropTypes.func.isRequired,
};
 
export default ContactResponse;