import { faCodeBranch, faEnvelope, faHome, faPassport, faUser } from '@fortawesome/free-solid-svg-icons';

const SECTIONS = [
  {
    id: 'start',
    transKey: 'sections.start',
    icon: faHome,
  },
  {
    id: 'about',
    transKey: 'sections.about',
    icon: faUser,
  },
  {
    id: 'projects',
    transKey: 'sections.projects',
    icon: faPassport,
  },
  {
    id: 'skills',
    transKey: 'sections.skills',
    icon: faCodeBranch,
  },
  {
    id: 'contact',
    transKey: 'sections.contact',
    icon: faEnvelope,
  },
];

export default SECTIONS;