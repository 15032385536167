import React from 'react';
import PropTypes from 'prop-types';
import styles from './TextareaField.module.scss';
import clsx from 'clsx';

const TextareaField = ({ id, value, label, error, placeholder, onChange }) => {
  return ( 
    <div className={clsx([styles.root, error && styles.error])}>
      <textarea 
        id={id}
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <label
        className={styles.label}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
}

TextareaField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
};
 
export default TextareaField;