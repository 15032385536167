import React from 'react';
import clsx from 'clsx';
import styles from './LangMobileButtons.module.scss';
import { useTranslation } from 'react-i18next';

import LANGUAGES from '../../../../settings/languages';

const LangMobileButtons = () => {
  const { i18n } = useTranslation();

  return ( 
    <div className={styles.root}>
      {
        LANGUAGES.map(language => (
          <button 
            className={clsx([styles.button, language.value === i18n.language && styles.active])}
            key={`mobile-${language.name}`}
            onClick={() => {
              i18n.changeLanguage(language.value)
            }}
          >
            {language.name}
          </button>
        ))
      }
    </div>
  );
}
 
export default LangMobileButtons;