import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from '../../../common/Paragraph/Paragraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import styles from './SkillsDescription.module.scss';
import clsx from 'clsx';

const SkillsDescription = ({
  skillsGroup
}) => {

  const { t } = useTranslation();

  return ( 
    <div className={styles.root}>
      <div className={clsx([styles.infoBox, styles.smallDescription])}>
        <Paragraph variant='dark'>
          {
            skillsGroup.description.name &&
              <strong>
                {t(skillsGroup.description.name)}
              </strong>
            }
            <span>
              {t(skillsGroup.description.info)}
            </span>
        </Paragraph>
      </div>
      <div className={clsx([styles.infoBox, styles.graph])}>
        {
          skillsGroup.technologies.map((technology) => (
            <div className={styles.graphItem} key={technology.name}>
              <div 
                className={styles.technology}
              >
                {
                  technology.icon ?
                    <FontAwesomeIcon 
                      className={styles.icon}
                      icon={technology.icon} 
                      color={technology.color}
                    />
                    :
                    <img 
                      src={technology.svg}
                      alt={technology.name}
                    />
                }
                <span>
                  {technology.name}
                </span>
              </div>
              {
                technology.nested &&
                 <ul key="nested" className={styles.nestedList}>
                   {
                     technology.nested.map(nestedTechn => (
                      <li key={nestedTechn}>
                        {nestedTechn}   
                      </li>
                     ))
                   }
                 </ul>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

SkillsDescription.propTypes = {
  skillsGroup: PropTypes.object.isRequired,
};
 
export default SkillsDescription;