const LANGUAGES = [
  {
    name: 'PL',
    value: 'pl',
  },
  {
    name: 'ENG',
    value: 'eng',
  }
];

export default LANGUAGES;