import React, { Suspense, useEffect } from 'react';
import Header from './components/layout/Header/Header';
import MainLayout from './components/layout/MainLayout/MainLayout';
import Navigation from './components/layout/Navigation/Navigation';
import Footer from './components/layout/Footer/Footer';
import About from './components/sections/About/About';
import Skills from './components/sections/Skills/Skills';
import Projects from './components/sections/Projects/Projects';
import Contact from './components/sections/Contact/Contact';
import { translationsEng } from './translations/eng';
import { translationsPl } from './translations/pl';
import i18n from 'i18next';
import { initReactI18next  } from 'react-i18next';

import Aos from 'aos';
import 'aos/dist/aos.css';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      pl: { translation: translationsPl },
      eng: { translation: translationsEng },
    },
    lang: 'eng',
    fallbackLng: 'eng',
    interpolation: { escapeValue: false },
  })

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <Suspense fallback="Loading...">
      <Header />
      <MainLayout>
        <About />
        <Projects />
        <Skills />
        <Contact />
      </MainLayout>
      <Footer />
      <Navigation />
    </Suspense>
  );
}

export default App;