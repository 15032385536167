import React from 'react';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import Section from '../../layout/Section/Section';
import Row from '../../layout/Row/Row';
import ShortInfo from './ShortInfo/ShortInfo';
import SmallHeader from '../../common/SmallHeader/SmallHeader';
import Paragraph from '../../common/Paragraph/Paragraph';
import SECTIONS from '../../../settings/sections';
import IconLink from '../../common/IconLink/IconLink';
import { useTranslation } from 'react-i18next';

import styles from './About.module.scss';

import ABOUT_ITEMS from '../../../settings/aboutItems';

const About = () => {

  const { t } = useTranslation();

  return ( 
    <Section 
      variant="light"
      id={SECTIONS[1].id}
      icon={SECTIONS[1].icon}
    >
      <Row>
        {
          ABOUT_ITEMS.map(item => (
            <ShortInfo 
              key={item.transKey}
              {
                ...item
              }
            />
          ))
        }
      </Row>
      <div>
        <div className={styles.information}>
          <Row>
            <div data-aos='zoom-in'  className={styles.iconLink}>
              <IconLink 
                to="projects"
                name={t('seeProjects')}
                icon={faLaptopCode} 
              />
            </div>
            <div data-aos="" className={styles.informationDetails}>
              <SmallHeader variant="dark">
                {t('aboutMe.more.title')}
              </SmallHeader>
              <div className={styles.text}> 
                <Paragraph variant="dark">
                  {t('aboutMe.more.description')}
                </Paragraph>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Section>
  );
}
 
export default About;