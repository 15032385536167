import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import styles from './NavLink.module.scss';

const NavLink = ({ sectionId }) => {
  const { t } = useTranslation();

  return ( 
    <li className={styles.root}>
      <Link 
        to={sectionId}
        activeClass={styles.active}
        className={styles.link}
        spy={true}
        offset={-36}
        smooth={true}
        duration={500}
      >
        {t(`sections.${sectionId}`)}
      </Link>
    </li>
  );
}

NavLink.propTypes = {
  sectionId: PropTypes.string.isRequired,
};
 
export default NavLink;