const ABOUT_ITEMS = [
  {
    imageUrl: "/images/technologies.png",
    transKey: "aboutMe.modern",
    animation: "fade-right",
  },
  {
    imageUrl: "/images/responsive.png",
    transKey: "aboutMe.responsive",
    animation: "fade-up",
  },
  {
    imageUrl: "/images/user-friendly.png",
    transKey: "aboutMe.friendly",
    animation: "fade-left",
  },
];

export default ABOUT_ITEMS;