import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import clsx from 'clsx';

const Button = ({ onClick, variant, children, ...props }) => {
  return ( 
    <button
      onClick={onClick}
      className={clsx([styles.root, variant && styles[variant]])}
      {...props}
    >
      <span className={styles.content}>
        {children}
      </span>
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.string,
};
 
export default Button;