import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '../Backdrop/Backdrop';
import SmallHeader from '../SmallHeader/SmallHeader';
import Portal from '../../layout/Portal/Portal';
import Paragraph from '../Paragraph/Paragraph';
import Button from '../Button/Button';
import styles from './Alert.module.scss';

const Alert = ({ title, positive, message, onClick }) => {
  return ( 
    <Portal>
      <Backdrop />
      <div className={styles.root}>
         <SmallHeader variant='dark'>
           {title}
        </SmallHeader>
        <div className={styles.content}>
          <Paragraph variant="dark">
            {message}
          </Paragraph>
        </div>
        <Button
          variant={positive ? 'positive' : 'normal'}
          onClick={onClick}
        >
          Ok
        </Button>
      </div>
    </Portal>
  );
}

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  positive: PropTypes.bool,
};
 
export default Alert;