import React from 'react';  
import PropTypes from 'prop-types';
import styles from './Row.module.scss';

const Row = ({ children, justify }) => {
  return ( 
    <div 
      style={{
        justifyContent: justify,
      }}
      className={styles.root}
    >
      {children}
    </div>
  );
}

Row.propTypes = {
  justify: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
};

Row.defaultProps = {
  justify: 'center',
};
 
export default Row;