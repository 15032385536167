import React from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './NavLinkMobile.module.scss';

const NavLinkMobile = ({ sectionId, onClick }) => {

  const { t } = useTranslation();

  return ( 
    <li className={styles.root}>
      <Link
        onClick={onClick}
        className={styles.link}
        activeClass={styles.active}
        to={sectionId}
        smooth={true}
        spy={true}
        duration={500}
      >
        <FontAwesomeIcon className={styles.icon} icon={faChevronRight}/>
        <span className={styles.name}>{t(`sections.${sectionId}`)}</span>
        <FontAwesomeIcon className={styles.icon} icon={faChevronLeft}/>
      </Link>
    </li>
  );
}

NavLinkMobile.propTypes = {
  sectionId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
 
export default NavLinkMobile;