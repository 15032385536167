import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { faShare, faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SmallHeader from '../SmallHeader/SmallHeader';
import IconLink from '../../common/IconLink/IconLink';
import Button from '../Button/Button';
import Paragraph from '../Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';

import styles from './Card.module.scss';
import clsx from 'clsx';

const Card = ({ imageUrl, index, onSelectProject, languages, title, category, smallInfo, technologies }) => {

  const { t } = useTranslation();

  const handleSelectProject = useCallback(() => onSelectProject(index), [onSelectProject, index]);

  const [side, setSide] = useState('face');

  const handleGoToBack = useCallback((e) => {
    e.preventDefault();
    setSide('back');
  }, [setSide]);
  const handleGoToFace = useCallback((e) => {
    e.preventDefault();
    setSide('face');
  }, [setSide]);

  return ( 
    <div className={clsx([styles.root, side === 'back' && styles.rotated])}>
      <div className={styles.frontSide}>
        <div className={styles.imageWrapper}>
          <img src={imageUrl} alt={title}/>
          <div className={styles.actions}>
            <IconLink 
              onClick={handleGoToBack}
              variant="dark"
              asButton
              name={t('card.rotateBack')}
              icon={faShare}
            />
            <Paragraph variant="dark">
            {t('card.checkTechnologies')}
            </Paragraph>
          </div>
          {
            languages.map((lang, index) => (
              <div key={lang} className={clsx([styles.langBar, styles[`langBar_${index}`], styles[lang]])}>
                {lang}
              </div>
            ))
          }
        </div>
        <div className={styles.content}>
          <SmallHeader variant="dark">
            {t(title)}
          </SmallHeader>
          <div className={styles.description}>
            <Paragraph variant="dark">
              {category}
            </Paragraph>
            <Paragraph variant="dark">
              {smallInfo}
            </Paragraph>
          </div>
          <div className={styles.btnWrapper}>
            <Button
              onClick={handleSelectProject} 
              variant="small"
            >
              {t('card.checkMore')}
            </Button>
          </div>
          <div className={styles.actionsFace}>
            <IconLink 
              onClick={handleGoToBack}
              variant="dark"
              asButton
              name={t('card.rotateBack')}
              icon={faShare}
            />
          </div>
        </div>  
      </div>
      <div className={styles.backSide}>
        <SmallHeader variant="dark">
          {title}
        </SmallHeader>
        <div className={styles.content}>
          {
            technologies.map(technology => (
              <div key={technology.name} className={styles.technology}>
                <div className={styles.technologyMain}>
                  <p>
                    <span style={{ color: technology.color}}>
                      <FontAwesomeIcon icon={technology.icon}/>
                    </span>
                    <span className={styles.technologyName}>
                      {technology.name}
                    </span>
                  </p>
                </div>
                <div className={styles.nestedTechnologies}>
                  {
                    technology.nested.map(nestedTechn => (
                      <p key={nestedTechn}>- {nestedTechn}</p>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
        <div className={styles.actionsBack}>
          <IconLink 
            onClick={handleGoToFace}
            variant="dark"
            asButton
            name={t('card.rotateFront')}
            icon={faReply}
          />
        </div>
      </div>
  </div>
  );
}

Card.propTypes = {
  index: PropTypes.number.isRequired,
  onSelectProject: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  smallInfo: PropTypes.string.isRequired,
  technologies: PropTypes.array,
}
 
export default Card;